import ZoteroPublications from '../node_modules/zotero-publications/dist/zotero-publications.js';
import './styles/mypubstyles.scss';
new ZoteroPublications(
	3311188,
	document.getElementById('container'),
	{ 
		'group': 'type',
		'apiBase': 'api.zotero.org'
	}
);
console.log('hellp');
